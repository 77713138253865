import React from 'react'
import Slider from 'react-slick'
import {Link} from 'gatsby'
import "slick-carousel/slick/slick.css"

export default class JummnotronSlider extends React.Component {
  render() {
    const settings = {
      className: "slider variable-width jumotron-slide",
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 8000,
      dots: true,
      infinite: true,
      speed: 300,
      slidesToShow: 1,
      centerMode: true,
      variableWidth: true,
      variableHeight: true,
      centerPadding: "0px"
    }
    if (this.props.movies.length < 1) {
      return ('loading')
    } else {

      const items = this.props.movies.map(movie => {
        const m = movie.slug ? movie : movie.node;
        return <div><Link to={m.slug} ><img src={m.poster_thumb && m.poster_thumb.url} height="256"/></Link></div> // width={m.poster_thumb && m.poster_thumb.x}
      })

      return (
        <div className="hero-carousel">
          <Slider {...settings}>
            {items}
          </Slider>
        </div>
      )
    }
  }
}