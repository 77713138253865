import React from "react"
import {Link} from "gatsby"
import Row, {RowMobile, RowMobileSM,RowSM} from "./Row"
import './Rows.css'
import img from '../images/mort.de.stalline.jpg'
// import { is_touch_device } from "../utils"

class Rows extends React.Component {
  state = {
    img: img,
    isMobile: false,
  }

  render() {

    let isMobile = false;

    // if (typeof window !== `undefined`) {
    //   isMobile = window.innerWidth <= 767 || is_touch_device()
    // }
    
    let rows

    const title = this.props.size === 'sm' 
    && <h2 id="popular-posts" id={this.props.title}><strong>{this.props.title}</strong></h2>
    || <h2 className="mb-1 mb-md-2" id={this.props.title}><strong>{this.props.title}</strong></h2>

    if (isMobile){
      rows = this.props.size === 'sm' 
      && <RowMobileSM movies={this.props.movies} />
      || <RowMobile movies={this.props.movies} />
    } else {
      rows = this.props.size === 'sm' 
      && <RowSM movies={this.props.movies} />
      || <Row movies={this.props.movies} querystring={this.props.querystring} rowblock={this.props.rowblock} fetchNextPage={this.props.fetchNextPage}
          sendImpressions = {this.props.sendImpressions}
          addImpressionId = {this.props.addImpressionId}
         />
    }




    return(
      <div className="container-fluid home">
          <div className="row">
            <div className="col-md-12 col-xs-12 p-3">
              {/* <Lazy ltIE9> */}
                <div className="d-flex flex-row flex-wrap justify-content-between align-items-start align-items-md-center">
                    {title}
                    {!this.props.disabledMoreButton &&
                    <Link to={this.props.moreUrl || '/search/'} state={this.props.params || ''}><button type="button" class="btn btn-outline-danger btn-sm mb-3 mb-md-0">Voir plus</button></Link>
                    }
                </div>
                  {rows}
              {/* </Lazy> */}
            </div>
          </div>
      </div>

    )
  }
}

export default Rows