import React from "react"
import {graphql, navigate} from "gatsby"

import Layout from '../components/Layout'
import Metadata from '../components/Metadata'
import Rows from "../components/Rows"

import {GENRES, GENRES_TABLE, GENRES_TABLE_FR} from '../const'
import HomeBody from "../components/HomeBody"
import { Trans } from "react-i18next"
import JummnotronSlider from "../components/JumbotronSlider"

const manuallySetMovies = {
  "/browse/free_animation": [
    {
      slug: "/t/les-chroniques-de-zorro-s",
      poster_thumb: {
        url: "https://img.cinetimes.org/img/cache/e5/c0/e5c05def99b05253d528a53efda97055.jpg"
      }
    },
    {
      slug: "/t/famille-pirate-s",
      poster_thumb: {
        url: "https://image.tmdb.org/t/p/w200//7izYBNMra45JSWZ9ctNmiX6JKZx.jpg"
      }
    },
    {
      slug: "/t/marsupilami-s",
      poster_thumb: {
        url: "https://img.cinetimes.org/img/cache/22/f9/22f97d2dd94de85ad850bc5acb2dc922.jpg"
      }
    },
    {
      slug: "/t/dc-super-hero-girls-s",
      poster_thumb: {
        url: "https://image.tmdb.org/t/p/w200//6BIHNmQHktFWkprlKPv7Q5h7Nb7.jpg"
      }
    },
    {
      slug: "/t/titeuf-s",
      poster_thumb: {
        url: "https://image.tmdb.org/t/p/w200//58BLXyYTVx1yw78Cu83itWNhE3F.jpg"
      }
    },
    {
      slug: "/t/oggy-et-les-cafards",
      poster_thumb: {
        url: "https://image.tmdb.org/t/p/w200//Aoid1dnUIiJRWPQJuT7fsECPQld.jpg"
      }
    },
    {
      slug: "/t/les-schtroumpfs-12-09-1981-s",
      poster_thumb: {
        url: "https://image.tmdb.org/t/p/w200//lJNHoPkjjAnmGwoctPDM5wG2W7l.jpg"
      }
    },
    {
      slug: "/t/tom-et-jerry-show-s",
      poster_thumb: {
        url: "https://image.tmdb.org/t/p/w200//vUFQVpBPiUKArQJ2Fj44qcsyydH.jpg"
      }
    },
 
  ]
}

class Browse extends React.Component {
  state = {
    movies: [],
  }
  
  
  render() {

    const { path } = this.props

    let title = <Trans>Films gratuits</Trans>
    if (path.includes("/browse/free_animation")) title = <Trans>Dessins animés gratuits</Trans>
    else if (path.includes("/browse/free_documentary")) title = <Trans>Documentaires à voir ici</Trans>
      
    return (
      <Layout>
        <Metadata title="Films, Dessins Animés et Documentaires Gratuits en Streaming" pathname={this.props.location.pathname}/>
        
        { path.includes('free') &&
          <div className="jumbotron bg-blue mb-0 rounded-0 d-md-flex pt-4 pb-3">
            <div className="container">
              <div className="row">
                <div className="text-white m-auto">
                  <h3 ><strong className="helsinki">{title}</strong>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        }
        <HomeBody watchOn={{cinetimes: true}} infinite={true} slug={path}/>
      </Layout>
    )
  }
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default Browse
